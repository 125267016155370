<script setup lang="ts">
import type { PropType } from 'vue'
import { WarningActionType, type Warning } from '@warnings'

defineProps({
  warning: {
    type: Object as PropType<Warning>,
    required: true,
  },
})

const eventsAnalytics = useEventsAnalytics()
</script>

<template>
  <div>
    <!-- Header -->
    <div class="flex shrink-0 items-center justify-between bg-gray-200 px-4 pt-4 pb-2">
      <span class="text-base font-semibold text-gray-900">{{ warning.title }}</span>
      <!--      <LButton
        variant="secondary"
        icon="close"
        :icon-size="24"
        size="sm"
        class="border-none !bg-transparent"
        rounded
        @click="onClose"
      />-->
    </div>
    <!-- Body -->
    <div class="flex items-center bg-gray-200 px-4 pb-4 overflow-y-auto">
      <p class="text-sm text-gray-900">{{ warning.message }}</p>
    </div>
    <!-- Footer -->
    <div
      v-if="warning.actionType === WarningActionType.URL && warning.actionValue"
      class="flex items-center justify-end shrink-0 bg-gray-200 px-4 pb-4 space-x-2"
    >
      <LButton
        variant="link"
        size="sm"
        class="border border-primary"
        :to="warning.actionValue"
        @click="eventsAnalytics.track('Click Warning Action')"
      >
        {{ $t('general.seeMore') }}
      </LButton>
    </div>
  </div>
</template>
